import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useGetAccessToken } from '../../hooks';
import { YesButton, NoButton } from '../reusable/Buttons';
import H2 from '../reusable/H2';
import { TextInput, DropDownInput, NumInput, MultiChoiceInput } from '../reusable/Input';
import Api from '../../Api';
import {
  setMap,
  mapSelectCpts,
  mapMarkerPosition,
  mapActiveMarker,
} from '../../redux/mapSlice';
import {
  mishStatus,
  mishError,
  mishCreateMission,
  mishCpts,
  mishInit,
  mishConf,
  mishAddCustomCpt,
  mishDeleteCustomCpt,
  mishName,
  mishCoords,
} from '../../redux/missionSlice';
import { initMissionReq, missionReqReducer } from '../../state';


const randFloat = (min, max) => {
  return parseFloat(Math.random() * (max - min) + min);
};

const randPoint = (lng, lat, maxDist) => {
  const distance = randFloat(0, 1) * maxDist;
  const direction = randFloat(0, 360);

  const distanceN = distance * Math.sin((direction * Math.PI) / 180);
  const distanceE = distance * Math.cos((direction * Math.PI) / 180);

  const coeff = 360 / 40008; // degrees / circumference
  const deltaLng = (coeff / Math.cos((lat * Math.PI) / 180)) * distanceE;
  const deltaLat = coeff * distanceN;

  return {lng: lng + deltaLng, lat: lat + deltaLat};

};

const getRandMission = (coords, maxDist, n) => {
  let mission = [];
  for (let i = 0; i < n; i++) {
    const name = `random ${i}`;
    const point = randPoint(coords.lng, coords.lat, maxDist);
    const tags = [];
    mission.push({
      name: name,
      location: {
        type: "point",
        coordinates: [point.lng, point.lat]
      },
      gmapsLoc: point,
      tags: tags,
      visited: false,
    });
  }
  return mission;
};




function checkReq(missionReq) {
  const lng = typeof missionReq.data.coords[0];
  const lat = typeof missionReq.data.coords[1];
  return lng === 'number' && lat === 'number';
}




const CreateMission = () => {

  const getAccessToken = useGetAccessToken();

  const [allTags, setAllTags] = useState([]);

  const [missionReq, missionReqDispatch] = useReducer(missionReqReducer, initMissionReq);

  const dispatch = useDispatch();

  const missionStatus = useSelector((state) => state.mission.status);
  const mission = useSelector((state) => state.mission.cpts);

  const modeOptions = [
    { value: 'database', text: 'Community checkpoints' },
    { value: 'random', text: 'Random locations' },
    { value: 'custom', text: 'Custom checkpoints' },
  ];

  // reset mission state
  useEffect(() => {
    dispatch(mishInit());
  }, [dispatch]);




  // MAP STUFF /////////////////////////////////////////////////

  const markerPosition = useSelector((state) => state.map.markerPosition);

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: true, mapType: 'select'}));
  }, [dispatch]);

  // Effect for setting display cpts
  useEffect(() => {
    dispatch(mapSelectCpts(mission));
  }, [dispatch, mission]);

  //////////////////////////////////////////////////////////////




  // get the available tags from the backend
  useEffect(() => {
    const getAllTags = async () => {
      const res = await Api.GET('/tags');
      setAllTags(res?.data ?? []);
    }
    getAllTags();
  }, []);







  // effect for getting missions when cpt source is random or database
  useEffect(() => {

    function getRandData() {
      const m = getRandMission(markerPosition, missionReq.data.radius, missionReq.data.n);
      dispatch(mishCreateMission({cpts: m, conf: missionReq.data}));
      dispatch(mishStatus('done'));
    }

    async function getData() {
      const res = await Api.POST('/mission', {data: missionReq.data});
      if (res.status === 200) {
        let m = res?.data ?? [];
        for (let i = 0; i < m.length; i++) {
          m[i].visited = false;
          m[i].gmapsLoc = {
            lng: m[i].location.coordinates[0],
            lat: m[i].location.coordinates[1],
          };
        }
        //dispatch(mishCpts(m));
        dispatch(mishCreateMission({cpts: m, conf: missionReq.data}));
      } else {
        dispatch(mishError(res.status));
      }
      dispatch(mishStatus('received'));
      dispatch(mishStatus('done'));
    }

    // no radius or n values provided
    if (missionStatus === 'requested' && (missionReq.data.radius === '' || missionReq.data.n === '')) {
      dispatch(mishStatus('done'));
      return;
    }

    // get mission with random or database checkpoints
    if (missionStatus === 'requested' && missionReq.data.cptSource === 'random' && checkReq(missionReq)) {
      getRandData();
    } else if (missionStatus === 'requested' && missionReq.data.cptSource === 'database' && checkReq(missionReq)) {
      getData();
    }
  }, [missionStatus, markerPosition, missionReq, dispatch]);






  // update the missionReq whenever markerPosition changes
  useEffect(() => {
    missionReqDispatch({ type: 'set-coords', payload: markerPosition });
  }, [markerPosition]);

  const setCptSource = useCallback((source) => {
    missionReqDispatch({ type: 'set-cpt-source', payload: source });
    dispatch(mishCpts([]));
  }, [dispatch]);

  const setTags = useCallback((tags) => {
    missionReqDispatch({ type: 'set-tags', payload: tags });
  }, []);

  const setRadius = useCallback((radius) => {
    missionReqDispatch({ type: 'set-radius', payload: radius });
  }, []);

  const setN = useCallback((n) => {
    missionReqDispatch({ type: 'set-n', payload: n });
  }, []);




  // CUSTOM MISSION STUFF //////////////////////////////////////////////////////////////////

  const activeMarker = useSelector((state) => state.map.activeMarker);

  // when user selects custom checkpoint mode
  useEffect(() => {
    if (missionReq.data.cptSource === 'custom') {
      const customConf = {
        cptSource: 'custom',
        coords: [0, 0],
        tags: [],
        radius: 0,
        n: 0,
      };
      dispatch(mishCpts([]));
      dispatch(mishConf(customConf));
      missionReqDispatch({ type: 'init-custom', payload: null });
    }
  }, [dispatch, missionReq.data.cptSource]);



  const customAddCpt = useCallback(() => {
    const { lng, lat } = markerPosition;
    const newCpt = {
      name: "Custom Checkpoint",
      location: {
        type: "point",
        coordinates: [lng, lat],
      },
      gmapsLoc: markerPosition,
      tags: [],
      visited: false,
    };
    dispatch(mapMarkerPosition(null));
    dispatch(mishAddCustomCpt(newCpt));
  }, [dispatch, markerPosition]);




  const customDeleteCpt = useCallback(() => {
    dispatch(mishDeleteCustomCpt(activeMarker));
    dispatch(mapActiveMarker(null));
  }, [dispatch, activeMarker]);



  const controlBoxStyle = 'p-[0.15em] my-[1em] bg-bgMain';





  // SAVE MISSION

  const missionObj = useSelector((state) => state.mission);
  const name = useSelector((state) => state.mission.name);

  const [wantSubmit, setWantSubmit] = useState(false);

  // if custom mission, calculate the mission center
  useEffect(() => {
    if (missionObj.conf?.cptSource === 'custom') {
      dispatch(mishCoords());
    }
  }, [dispatch, missionObj.conf?.cptSource, missionObj.cpts]);

  useEffect(() => {
    async function postMission() {
      const token = await getAccessToken();
      await Api.POST('/mission/save', {token: token, data: missionObj});
      dispatch(mishInit());
      missionReqDispatch({ type: 'init', payload: null });
    }
    if (wantSubmit) {
      setWantSubmit(false);
      postMission();
    }
  }, [dispatch, wantSubmit, setWantSubmit, missionObj, getAccessToken]);







  return (
    <div>

      {/*save ? <SaveMission setSave={setSave}/> : <></>*/}


      {missionReq.data?.cptSource === 'custom' ? (
        <div className="flex mb-[2em]">
          <NoButton
            onClick={customDeleteCpt}
            text="Delete checkpoint"
            isActive={typeof activeMarker === 'number'}
          />
          <YesButton
            onClick={customAddCpt}
            text="Add checkpoint"
            isActive={markerPosition}
          />
        </div>
      ) : (<></>)}




      <div className={controlBoxStyle}>
        <div className="text-center">
          <H2 text='Mission Parameters' />
        </div>
        
        <div className="">

          <div className="text-left py-[1em]">
            <DropDownInput options={modeOptions} value={missionReq.data.cptSource} setValue={setCptSource} />
          </div>

          {missionReq.data.cptSource !== 'custom' ?
            (
              <div>
                {missionReq.data.cptSource === 'database' ? (
                  <div className="pb-[1em]">
                    <MultiChoiceInput options={allTags} chosen={missionReq.data.tags} setChosen={setTags} />
                  </div>
                ) : (<></>)}

                <div className="pb-[1em]">
                  <NumInput value={missionReq.data.n} setValue={setN} label='Checkpoints' />
                </div>

                <div className="pb-[1em]">
                  <NumInput value={missionReq.data.radius} setValue={setRadius} isNumeric={true} label='Radius' />
                </div>

                <div className="relative my-[.5em] mx-[1em]">
                  <YesButton
                    text='Create mission'
                    onClick={() => dispatch(mishStatus('requested'))}
                    isActive={markerPosition}
                  />
                </div>


              </div>
            ) : (<></>)
          }

        </div>



      </div>





      {/* SAVE MISSION */}
      <div className={controlBoxStyle}>
        <div className="text-center">
          <H2 text='Name & Save Mission' />
        </div>
        <div className="mb-[0.5em]">
          <TextInput label="Name" value={name} setValue={(_name) => dispatch(mishName(_name))} />
        </div>
        <div className="h-fit">
          <YesButton
            onClick={() => setWantSubmit(true)}
            text="Save"
            isActive={missionObj.cpts.length > 0 && missionObj.name}
          />
        </div>
      </div>



    </div>
  );
};

export default CreateMission;

