import { useEffect, memo } from 'react'
import { atptData } from '../../redux/attemptNoAccountSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isAtCheckpoint } from '../../helpers';
import MissionAttemptDashboard from '../mission/MissionAttemptDashboard';
import { updateAttempt } from './helpers';
import { setMap, mapAttemptCpts } from '../../redux/mapSlice';




const MissionDisplayMap = () => {

  const dispatch = useDispatch();
  const attempt = useSelector((state) => state.attemptNoAccount);




  // MAP STUFF /////////////////////////////////////////////////

  const loc = useSelector((state) => state.map.loc);

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: true, mapType: 'attempt'}));
  }, [dispatch]);

  // Effect for setting the mapAttemptCpts
  useEffect(() => {
    dispatch(mapAttemptCpts(attempt.data?.cpts));
  }, [dispatch, attempt.data?.cpts]);

  //////////////////////////////////////////////////////////////






  // Effect for updating attempt state when checkpoint(s) are reached
  useEffect(() => {

    if (!loc || !attempt.data?.cpts) {
      return;
    }

    const visited = [];
    for (let i = 0; i < attempt.data.cpts.length; i++) {
      const cpt = attempt.data.cpts[i];
      if (!cpt.visited) {
        if (isAtCheckpoint(loc, cpt.gmapsLoc)) {
          visited.push(i);
        }
      }
    }

    if (visited.length > 0) {
      const cptsCopy = [];
      for (let i = 0; i < attempt.data.cpts.length; i++) {
        cptsCopy.push({...attempt.data.cpts[i]});
      }

      const copy = {
        start: attempt.data.start,
        end: attempt.data.end,
        missionName: attempt.data.missionName,
        missionOwner: attempt.data.missionOwner,
        missionOwnerId: attempt.data.missionOwnerId,
        attemptee: attempt.data.attemptee,
        attempteeId: attempt.data.attempteeId,
        cpts: cptsCopy,
        cptsVisited: attempt.data.cptsVisited,
      };

      const newAttempt = updateAttempt(copy, visited);
      dispatch(atptData(newAttempt));
    }



  }, [loc, dispatch, attempt]);


  return (
    <div>
      <MissionAttemptDashboard />
    </div>
  );
};

export default memo(MissionDisplayMap);

